import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, FormGroup, Button } from "reactstrap";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch } from "react-redux";
import { getEdpCourseSubCatgeory, getEdpCourseCatgeory } from "../../redux/actions/EdpCourseActions";

const CreateEdpCourse = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [text, setText] = useState("");
    const [hindiText, setHindiText] = useState("");
    const [edpCategory, setEdpCategory] = useState([]);
    const [courseEdpCategory, setEdpSubCategory] = useState([]);
    const [EdpCourseSubCategory, setEdpCourseSubCategory] = useState([]);
    const [edpSubSubCategory, setEdpSubSubCategory] = useState([]);
    const [formData, setFormData] = useState({
        title: "",
        hindi_title: "",
        url: "",
        hindi_url: "",
        duration: "",
        duration_hindi: "",
        total_pages: "",
        total_pages_hindi: "",
        thumbnail: "",
        thumbnail_hindi: "",
        category_id: "",
        sub_category_id: "",
        sub_Sub_Category_id: "",
        type: "Free",
        status: 1
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        AuthReducer: { auth },
        EdpCourseReducer: { edpCourseCategories },
    } = useSelector((state) => state);

    useEffect(() => {
        dispatch(getEdpCourseCatgeory(auth.token));
        dispatch(getEdpCourseSubCatgeory(auth.token));
    }, [auth]);

    const inputHandler = (e) => {
        const { name, value } = e.target;
        if (name === "thumbnail" && name === "thumbnail_hindi") {
            setFormData({ ...formData, [name]: e.target.files[0] });
        }
        if (name === "status") {
            setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    useEffect(() => {
        if (formData.category_id) {
            axios(
                `/api/admin/v1/get-edp-sub-category-list/${formData.category_id}`
            ).then(({ data }) => {
                setEdpCourseSubCategory(data?.data);
            }).catch((err) => console.error(err));
        } else {
            setEdpCourseSubCategory([]);
        }
    }, [formData.category_id]);

    useEffect(() => {
        if (formData.sub_category_id) {
            axios(
                `/api/admin/v1/edp-sub-sub-category-list/${formData.sub_category_id}`
            ).then(({ data }) => {
                setEdpSubSubCategory(data?.data);
            }).catch((err) => console.error(err));
        } else {
            setEdpSubSubCategory([]);
        }
    }, [formData.sub_category_id]);

    const handleSubmit = () => {
        setLoading(true);
        const form = new FormData();
        form.append('title', formData.title);
        form.append('hindi_title', formData.hindi_title);
        form.append('duration', formData.duration);
        form.append('duration_hindi', formData.duration_hindi);
        form.append('url', formData.url);
        form.append('hindi_url', formData.hindi_url);
        form.append('total_pages', formData.total_pages);
        form.append('total_pages_hindi', formData.total_pages_hindi);
        form.append('thumbnail', formData.thumbnail);
        form.append('thumbnail_hindi', formData.thumbnail_hindi);
        form.append('short_description', formData.short_description);
        form.append('hindi_short_description', formData.hindi_short_description);
        form.append('category_id', formData.category_id);
        form.append('sub_category_id', formData.sub_category_id);
        form.append('sub_Sub_Category_id', formData.sub_Sub_Category_id);
        form.append('description', text);
        form.append('hindi_description', hindiText);
        form.append('type', formData.type);
        form.append('status', formData.status ? '1' : "0");
        
        axios.post(`/api/admin/v1/add-edp-course`, form, {
            headers: { Authorization: `Bearer ${auth?.token}` },
        }).then(res => {
            toast.success(res.data.message);
            navigate(-1);
        }).catch(err => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data);
        }).finally(() => setLoading(false))

    };
    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <h5 className="text-white">Add New Edp Course</h5>
                </div>
            </div>
            <div className="bg-white px-3 py-4 rounded-2  my-4">
                <div className="row">
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Edp Category <span className="text-danger fs-4">*</span></Label>
                            <Input className="text-black" value={formData?.category_id} type="select" name="category_id" onChange={inputHandler}>
                                <option value="">Select</option>
                                {edpCourseCategories?.map?.((item) => {
                                    return (<option value={item.id} key={item.id}>{item?.name}</option>)
                                })}
                            </Input>
                            <span className="d-block text-danger">{" "}{errors?.category_id}{" "}</span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Edp Sub Category</Label>
                            <Input className="text-black" value={formData?.sub_category_id} type="select" name="sub_category_id" onChange={inputHandler}>
                                <option value="">Select</option>
                                {EdpCourseSubCategory?.map?.((item) => {
                                    return (
                                        <option value={item.id} key={item.id}>
                                            {item?.name}
                                        </option>
                                    );
                                })}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Edp Sub Sub Category</Label>
                            <Input className="text-black" value={formData?.sub_Sub_Category_id} type="select" name="sub_Sub_Category_id" onChange={inputHandler}>
                                <option value="">Select</option>
                                {edpSubSubCategory?.map?.((item) => {
                                    return (
                                        <option value={item.id} key={item.id}>
                                            {item?.name}
                                        </option>
                                    );
                                })}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Title <span className="text-danger fs-4">*</span></Label>
                            <Input value={formData?.title} name="title" invalid={errors?.title} onChange={inputHandler} />
                            <span className="d-block text-danger">{errors?.title}</span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Title Hindi</Label>
                            <Input value={formData?.hindi_title} name="hindi_title" invalid={errors?.hindi_title} onChange={inputHandler} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Url <span className="text-danger fs-4">*</span></Label>
                            <Input value={formData?.url} name="url" invalid={errors?.url} onChange={inputHandler} />
                            <span className="d-block text-danger">{errors?.url}</span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Url Hindi</Label>
                            <Input value={formData?.hindi_url} name="hindi_url" invalid={errors?.hindi_url} onChange={inputHandler} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Duration <span className="text-danger fs-4">*</span></Label>
                            <Input value={formData?.duration} name="duration" placeholder="duration in minutes only" invalid={errors?.duration} onChange={(e) => !isNaN(e.target.value) && inputHandler(e)} />
                            <span className="d-block text-danger">{errors?.duration}</span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Duration Hindi</Label>
                            <Input value={formData?.duration_hindi} name="duration_hindi" placeholder="duration in minutes only" invalid={errors?.duration_hindi} onChange={(e) => !isNaN(e.target.value) && inputHandler(e)} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Total Video</Label>
                            <Input value={formData?.total_pages} name="total_pages" invalid={errors?.total_pages} onChange={(e) => !isNaN(e.target.value) && inputHandler(e)} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Total Video Hindi</Label>
                            <Input value={formData?.total_pages_hindi} name="total_pages_hindi" invalid={errors?.total_pages_hindi} onChange={(e) => !isNaN(e.target.value) && inputHandler(e)} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Upload Thumbnail <span className="text-danger fs-4">*</span></Label>
                            <Input type="file" name="thumbnail" onChange={(e) => setFormData(prev => ({ ...prev, thumbnail: e.target.files[0] }))} />
                            <span className="d-block text-danger">{errors?.thumbnail}</span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Upload Thumbnail Hindi</Label>
                            <Input type="file" name="thumbnail_hindi" onChange={(e) => setFormData(prev => ({ ...prev, thumbnail_hindi: e.target.files[0] }))} />
                        </FormGroup>
                    </div>

                    <div className="col-md-4 offset-md-1" style={{ marginBlock: 15 }}>
                        <FormGroup switch>
                            <Input type="switch" checked={formData?.status} onChange={() =>
                                setFormData({ ...formData, status: !formData.status })}
                                name="status" />
                            <Label check>Status</Label>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-4">
                        <Button color="primary" onClick={handleSubmit} disabled={loading}>Submit</Button>
                        <Button className="ms-3" onClick={() => navigate(-1)}>Cancel</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateEdpCourse;
