import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { MdAdd } from "react-icons/md";
import { Spinner } from "reactstrap";
import { CSVLink } from "react-csv";
import { FcGallery } from "react-icons/fc";
import { BiSolidFileExport } from "react-icons/bi";
import { Input } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Pagination from "../Components/Paginate";
import { getUser } from "../redux/actions/UserAction";

const Userlist = () => {
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth },
    UserReducer: { user, loading, userCount },
    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("desc");
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const csvHead = [
    [
      "SL. No.",
      "Name",
      "Email",
      "Mobile",
      "User Type",
      "City",
      "Created At",
    ],
  ];
  const csvData = user.map((cv, index) => [
    index + 1,
    cv?.name,
    cv?.email,
    cv?.mobile,
    (cv?.user_type == 1) ? "User" : (cv?.user_type == 2) ? "Agent" : "",
    cv?.city,
    cv?.createdAt ?
      new Date(cv.createdAt).toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      }) : 'N/A'
  ]);
  const exportData = csvHead.concat(csvData);

  useEffect(() => {
    dispatch(getUser(auth?.token, paginate, page, search, sort, startDate, endDate));
  }, [auth, paginate, page, search, sort, startDate, endDate]);

  const toggle = () => setIsOpen((prev) => !prev);
  console.log(user, "user");
  const handleDel = (id) => {
    if (window.confirm("Are You Sure You Want To Delete This User")) {
      axios.delete(`/api/admin/v1/user-delete/${id}`, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      }).then((res) => {
        toast.success(res.data.message);
        dispatch(getUser(auth?.token, paginate, page, search, sort));
      }).catch((err) => toast.error("Somthing Went Wrong"));
    }
  };
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 text-white">User List</h5>
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "USERLIST" &&
                item?.permission?.permission === "create"
            ) && (
                <Link to="/user-form">
                  <Button className="bg-white text-black" style={{ color: "primary" }} size="sm">
                    <span className="me-2"><MdAdd size={22} /></span>
                    Add User
                  </Button>
                </Link>
              )}
          </div>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4" style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}>
        <div className="row">

          <div className="col-md-3 mb-2 ">
            <Input size={"sm"} value={search} placeholder="Search By Name, Mobile, Email" onChange={({ target: { value } }) => setSearch(value)} />
          </div>

          <div className="col-md-3 mb-2">
            <Input type="date" size="sm" value={startDate} placeholder="Start Date" onChange={({ target: { value } }) => setStartDate(value)} />
          </div>

          <div className="col-md-3 mb-2">
            <Input type="date" size="sm" value={endDate} placeholder="End Date" onChange={({ target: { value } }) => setEndDate(value)} />
          </div>

          <div className="col-md-2 mb-2 ">
            <button className="btn btn-primary btn-sm me-2 text-white" onClick={() => { setSearch(""); setStartDate(""); setEndDate(""); }}> Clear </button>
          </div>

          <div className="col-md-1">
            <CSVLink filename={"user_list.csv"} className="text-black ms-3" title="Export To CSV" data={exportData}>
              <BiSolidFileExport size={25} />
            </CSVLink>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center mx-auto">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className="col-md-12">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>SL. No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>User Type</th>
                    <th>City</th>
                    <th>Created At</th>
                    {allowedPermissions?.some?.(
                      (item) =>
                        item?.permission?.moduleType === "USERLIST" &&
                        item?.permission?.permission === "edit"
                    ) && <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {user?.map?.((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{item?.name}</td>
                        <td>{item?.email}</td>
                        <td>{item?.mobile}</td>
                        <td>{(item?.user_type == 1) ? "User" : (item?.user_type == 2) ? "Agent" : ""}</td>
                        <td>{item?.city}</td>
                        <td>
                          {item?.createdAt ?
                            new Date(item.createdAt).toLocaleString('en-GB', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                              hour12: true,
                            })
                            : 'N/A'}
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {allowedPermissions?.some?.(
                              (item) =>
                                item?.permission?.moduleType === "USERLIST" &&
                                item?.permission?.permission === "view-detail"
                            ) && (
                                <Link to={`/view-detail-user/${item?.id}?userType=user`}>
                                  <AiOutlineEye size={22} className="me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="View Locker Details" />
                                </Link>
                              )}
                            {allowedPermissions?.some?.(
                              (item) =>
                                item?.permission?.moduleType === "USERLIST" &&
                                item?.permission?.permission === "edit"
                            ) && (
                                <Link to={`/edit-user/${item?.id}`}>
                                  <AiOutlineEdit size={25} className="text-warning me-3" />
                                </Link>
                              )}
                            {allowedPermissions?.some?.(
                              (item) =>
                                item?.permission?.moduleType === "USERLIST" &&
                                item?.permission?.permission === "delete"
                            ) && (
                                <AiOutlineDelete size={22} className="text-danger cursor" onClick={() => handleDel(item.id)} />
                              )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <select id="paginate" onChange={(e) => setPaginate(e.target.value)} value={paginate} className="paging custom-select custom-select-page">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
            <div className="mx-3 py-2 text-black">
              Total Results : {userCount}
            </div>
          </div>
          <Pagination pageCount={userCount / paginate} onChange={(e) => setPage(e.selected + 1)} />
        </div>
      </div>
    </div>
  );
};

export default Userlist;
