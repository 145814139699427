import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { MdAdd } from "react-icons/md";
import { Spinner } from "reactstrap";
import Pagination from "../../Components/Paginate";
import { toast } from "react-toastify";
import { Input, Label, FormGroup } from "reactstrap";
import axios from "axios";
import { CSVLink } from "react-csv";
import { BiSolidFileExport } from "react-icons/bi";
import { getEdpCourseCatgeory, getEdpCourseSubCatgeory, getEdpCourseSubSubCategory } from "../../redux/actions/EdpCourseActions";
const EdpSubSubCategory = () => {
    const {
        AuthReducer: { auth },
        EdpCourseReducer: {
            edpCourseSubSubCategories,
            edpCourseSubSubCategoriesCount,
            loading,
        },
        PermissionReducer: { allowedPermissions },
    } = useSelector((state) => state);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("desc");
    const [paginate, setPaginate] = useState(10);
    const dispatch = useDispatch();
    const handleDel = (id) => {
        if (window.confirm("Are You Sure You Want To Delete !")) {
            axios.delete(`/api/admin/v1/edp-sub-sub-category-delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            }).then((res) => {
                toast.success(res.data.message);
                dispatch(getEdpCourseSubSubCategory(auth?.token));
            }).catch((err) => toast.error("Somthing Went Wrong"));
        }
    };

    useEffect(() => {
        dispatch(getEdpCourseCatgeory(auth.token));
    }, [auth]);
    useEffect(() => {
        dispatch(getEdpCourseSubCatgeory(auth.token));
    }, [auth]);
    useEffect(() => {
        dispatch(
            getEdpCourseSubSubCategory(auth?.token, paginate, page, search, sort)
        );
    }, [auth, paginate, page, search, sort]);
    console.log("edpCourseSubSubCategories", edpCourseSubSubCategories);
    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0 text-white">Edp Sub Sub Category</h5>
                        {allowedPermissions?.some?.((item) =>
                            item?.permission?.moduleType === "COURSESUBSUBCATEGORY" &&
                            item?.permission?.permission === "create"
                        ) && (
                                <Link to="/add-edp-sub-sub-category">
                                    <Button className="bg-white text-black" size="sm">
                                        <span className="me-2">
                                            <MdAdd size={22} />
                                        </span>
                                        Add Edp Sub Sub Category
                                    </Button>
                                </Link>
                            )}
                    </div>
                </div>
            </div>
            <div className="bg-white px-3 py-4 rounded-2  my-4" style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}>
                <div className="row">
                    <div className="col-md-3 mb-2">
                        <Input size={"sm"} value={search} placeholder="Search By Name" onChange={({ target: { value } }) => setSearch(value)} />
                    </div>
                    <div className="col-md-2 offset-md-7">
                        <Input type="select" size={"sm"} value={sort} onChange={({ target }) => setSort(target.value)}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </Input>
                    </div>
                </div>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center mx-auto">
                        <Spinner color="primary" />
                    </div>
                ) : (
                    <div className="col-md-12">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>SL. No.</th>
                                    <th>Name</th>
                                    <th>Title</th>
                                    <th>Course Category</th>
                                    <th>Course Sub Category</th>
                                    <th>Description</th>
                                    {allowedPermissions?.some?.(
                                        (item) =>
                                            item?.permission?.moduleType === "COURSESUBSUBCATEGORY" &&
                                            (item?.permission?.permission === "edit" ||
                                                item?.permission?.permission === "delete")
                                    ) && (
                                            <th>Action</th>
                                        )}
                                </tr>
                            </thead>
                            <tbody>
                                {edpCourseSubSubCategories?.map?.((item, index) => {
                                    return (
                                        <tr key={item.id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{item?.name}</td>
                                            <td>{item?.title}</td>
                                            <td>{item?.edp_categorie?.name}</td>
                                            <td>{item?.edp_sub_categorie?.name}</td>
                                            <td>{item?.description}</td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    {allowedPermissions?.some?.((item) =>
                                                        item?.permission?.moduleType ===
                                                        "COURSESUBSUBCATEGORY" &&
                                                        item?.permission?.permission === "edit"
                                                    ) && (
                                                            <Link to={`/edit-edp-sub-sub-category/${item?.id}`}>
                                                                <AiOutlineEdit
                                                                    size={22}
                                                                    className="text-warning me-3"
                                                                />
                                                            </Link>
                                                        )}
                                                    {allowedPermissions?.some?.(
                                                        (item) =>
                                                            item?.permission?.moduleType ===
                                                            "COURSESUBSUBCATEGORY" &&
                                                            item?.permission?.permission === "delete"
                                                    ) && (
                                                            <AiOutlineDelete
                                                                size={22}
                                                                className="text-danger"
                                                                onClick={() => handleDel(item.id)}
                                                            />
                                                        )}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                )}

                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                        <select
                            id="paginate"
                            onChange={(e) => setPaginate(e.target.value)}
                            value={paginate}
                            className="paging custom-select custom-select-page"
                        >
                            <option value="10" selected>
                                10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                        </select>
                        <div className="mx-3 py-2 text-black">
                            Total Results : {edpCourseSubSubCategoriesCount?.count}
                        </div>
                    </div>
                    <Pagination
                        pageCount={edpCourseSubSubCategoriesCount?.count / paginate}
                        pageRange={3}
                        onChange={(e) => setPage(e.selected)}
                    />
                </div>
            </div>
        </div>
    );
};

export default EdpSubSubCategory;
