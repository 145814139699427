import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useSocketContext } from "../../Context/SocketContext";
import { MdAdd, MdVerified } from "react-icons/md";
import { Spinner } from "reactstrap";
import { getConsultantList } from "../../redux/actions/ConsultantAction";
import { getCategories, getLockerDetails, getSubCategories, getUserWallet, getUsercallHistory } from "../../redux/actions/indexActions";
import { Input } from "reactstrap";
import { AiOutlineEye } from "react-icons/ai";
import Pagination from "../../Components/Paginate";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactPlayer from "react-player";
import { CSVLink } from "react-csv";
import moment from "moment";
import { BiSolidFileExport } from "react-icons/bi";
import { FaDownload, FaRegBuilding, FaUser } from "react-icons/fa";
import { FcOpenedFolder } from "react-icons/fc";
const ConsultantList = () => {
  const dispatch = useDispatch();
  const [isUserVerified, setIsUserVerified] = useState([]);
  const [status, setStatus] = useState([]);
  const [onlineStatus, setOnlineStatus] = useState([]);
  const [userType, setuserType] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [paginate, setPaginate] = useState(30);
  const [consultantDetails, setConsultantDetails] = useState({});
  const [expertVideo, setExpertVideo] = useState({});
  const { socket } = useSocketContext();
  const [search, setSearch] = useState("");
  const [subCategoryFilter, setSubCategoryFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subSubSubCategoryFilter, setSubSubCategoryFilter] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [modal, setModal] = useState(false);
  const [url, setUrl] = useState("");
  const [sort, setSort] = useState("desc");
  const [statusType, setStatusType] = useState("");
  const [startDate, setStartDate] = useState('NULL');
  const [endDate, setEndDate] = useState('NULL');
  const [categorys, setCategorys] = useState('NULL');
  const [subCategorys, setSubCategorys] = useState('NULL');
  const [subSubCategorys, setSubSubCategorys] = useState('NULL');
  const [procedureConsultantList, setProcedureConsultantList] = useState([]);
  const [totalConsultant, setTotalConsultant] = useState(0);
  const [activeConsultant, setActiveConsultant] = useState(0);
  const [localLoader, setLocalLoader] = useState(false);
  const [lockerDetail, setLockerDetail] = useState({});
  const [consultantWallet, setConsultantWallet] = useState({});
  const [consultantCallHistory, setConsultantCallHistory] = useState({});

  const [positions, setPositions] = useState({});
  const {
    AuthReducer: { auth },
    PermissionReducer: { allowedPermissions },
    consultantReducer: {
      consultants,
      // ConsultantCount,
      consultantss,
      activeConsultants,
      loading,
    },
  } = useSelector((state) => state);
  const {
    indexReducer: { categories, subSubCategoryCount, lockerDocs, walletAmount, callHistory },
  } = useSelector((state) => state);
  useEffect(() => {
    dispatch(getCategories(auth.token));
    dispatch(getSubCategories(auth.token));
  }, [auth]);

  useEffect(() => {
    setLocalLoader(true);
    axios(`/api/admin/v1/get-procedure-consultants?search=${search}&categorys=${categorys}&subCategorys=${subCategorys}&subSubCategorys=${subSubCategorys}&sort=${sort}&statusType=${statusType}&startDate=${startDate}&endDate=${endDate}&limitData=${paginate}&pageData=${paginate * page}`, {
      headers: { Authorization: `Bearer ${auth.token}` },
    }).then(res => {
      setProcedureConsultantList(res?.data?.data);
      setTotalConsultant(res?.data?.count);
      setActiveConsultant(res.data.activeCount);
      setLocalLoader(false);
    }).catch(err => console.log(err))
  }, [auth, paginate, page, search, categorys, subCategorys, subSubCategorys, sort, startDate, endDate, statusType])

  useEffect(() => {
    if (procedureConsultantList) {
      const initialPositions = {};
      procedureConsultantList.forEach(procedureConsultant => {
        initialPositions[procedureConsultant.id] = procedureConsultant.position;
      });
      setPositions(initialPositions);
    }
  }, [procedureConsultantList]);
  useEffect(() => {
    if (categorys) {
      // setFormData((prev) => ({ ...prev, subCategoryId: "" }));
      axios(`/api/web/v1/get-sectorBy-type/${categorys}`).then(({ data }) => {
        setSubCategories(data?.data);
      }).catch((err) => console.error(err));
    } else { setSubCategories([]); }
  }, [categorys]);

  useEffect(() => {
    if (subCategorys) {
      axios(`/api/web/v1/get-industry-type/${subCategorys}`).then(({ data }) => {
        setSubSubCategories(data?.data);
      }).catch((err) => console.error(err));
    } else { setSubSubCategories([]); }
  }, [subCategorys]);

  useEffect(() => {
    dispatch(getLockerDetails(lockerDetail?.id, lockerDetail?.userType));
  }, [lockerDetail]);

  useEffect(() => {
    dispatch(getUserWallet(consultantWallet?.id, consultantWallet?.userType));
  }, [consultantWallet]);

  useEffect(() => {
    dispatch(getUsercallHistory(10, page, search, consultantCallHistory?.id, consultantWallet?.userType));
  }, [consultantCallHistory]);

  const csvDataWallet = walletAmount?.transaction_detail?.map((cv) => [
    cv?.transaction_amount,
    cv?.transaction_type,
    cv?.transaction_detail,
  ]) || [];  // Ensure it's an empty array if undefined

  // const exportDataWallet = csvDataWallet.concat(csvDataWallet);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const totalItems = walletAmount?.transaction_detail?.length || 0;
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const currentItems = walletAmount?.transaction_detail?.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  ) || [];  // Ensure it's an empty array if undefined

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [history, setHistory] = useState(false);
  const getDuration = (startTime, endTime) => {
    const start = moment.utc(startTime);
    const end = moment.utc(endTime);
    if (!start.isValid() || !end.isValid()) {
      return "00";
    }
    const duration = moment.duration(end.diff(start));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  console.log("wallet Amount =>", walletAmount)
  console.log("call History =>", callHistory)

  const [activeIndex, setActiveIndex] = useState(0);
  const handleToggle = (index) => { setActiveIndex(activeIndex === index ? -1 : index); };
  const [activeFolderIndex, setActiveFolderIndex] = useState(0);
  const handleFolderToggle = (index) => { setActiveFolderIndex(activeFolderIndex === index ? -1 : index); };
  const handleDel = (id) => {
    if (window.confirm("Are You Sure Want To Delete Consultant ")) {
      axios.put(
        `/api/admin/v1/delete-consultant/${id}`, {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      ).then(({ data }) => {
        toast.success(data.message);
        dispatch(getConsultantList(auth.token));
      }).catch((err) => {
        toast.error(err?.response?.data?.message);
      });
    }
  };
  const handleVideo = (e, url) => {
    e.preventDefault();
    setUrl(url);
    setModal(true);
  };
  const toggle = () => setIsOpen((prev) => !prev);

  // useEffect(() => {
  //   dispatch(
  //     getConsultantList(
  //       auth?.token,
  //       paginate,
  //       page,
  //       search,
  //       categoryFilter,
  //       subCategoryFilter,
  //       subSubSubCategoryFilter,
  //       sort,
  //       startDate,
  //       endDate,
  //       statusType

  //     )
  //   );
  // }, [
  //   auth,
  //   paginate,
  //   page,
  //   search,
  //   categoryFilter,
  //   subCategoryFilter,
  //   subSubSubCategoryFilter,
  //   sort,
  //   startDate,
  //   endDate,
  //   statusType
  // ]);

  useEffect(() => {
    if (procedureConsultantList?.length) {
      setStatus(
        procedureConsultantList?.map?.((item) => {
          return { id: item.id, status: item?.status };
        })
      );
      setIsUserVerified(
        procedureConsultantList?.map?.((item) => {
          return { id: item.id, isUserVerified: item?.isUserVerified };
        })
      );
    }
  }, [procedureConsultantList]);
  const handlePositionUser = useCallback((id) => {
    axios
      .put(
        `/api/admin/v1/update-consultant-position/${id}`,
        { position: parseInt(positions[id]) },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000)
      })
      .catch((err) => toast.error(err.response.data?.message));
  }, [auth, positions, dispatch, paginate, page, search, sort]);

  const handleOnlineChange = useCallback((val, id) => {
    if (window.confirm("Are You Sure Want To Update Status Consultant ")) {
      axios
        .put(
          `/api/admin/v1/update-consultant-online-status/${id}`,
          { onlineStatus: parseInt(val) },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        )
        .then(
          (res) => toast.success(res.data?.message),
          setTimeout(() => {
            window.location.reload();
          }, 3000)
        )
        .catch((err) => toast.error(err.response.data?.message));
    }
  }, [, auth])
  const handleUserTypeChange = useCallback((val, id) => {
    if (window.confirm("Are You Sure Want To Update Consultant type ? ")) {
      axios
        .put(
          `/api/admin/v1/update-consultant-user-type/${id}`,
          { userType: parseInt(val) },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        )
        .then(
          (res) => toast.success(res.data?.message),
          setTimeout(() => {
            window.location.reload();
          }, 3000)
        )
        .catch((err) => toast.error(err.response.data?.message));
    }
  }, [, auth])
  const handleStatusChange = useCallback(
    (val, id) => {
      if (window.confirm("Are You Sure Want To Update Status Consultant ")) {
        setStatus((prev) =>
          prev.map((item) => {
            if (item.id === id) {
              return { ...item, status: val };
            }
            return item;
          })
        );
        axios
          .put(
            `/api/admin/v1/update-consultant-status/${id}`,
            { status: parseInt(val) },
            {
              headers: {
                Authorization: `Bearer ${auth.token}`,
              },
            }
          )
          .then(
            (res) => toast.success(res.data?.message),
            socket.emit("approvenow", id)
          )
          .catch((err) => toast.error(err.response.data?.message));
      }

    },

    [status, auth]
  );
  const csvHead = [
    [
      "name", "email", "mobile", "pincode", "city", "state", "gender",
      "experience", "dob", "designation", "websiteUrl", "region", "address", "status"
    ]
  ];
  const csvData = procedureConsultantList.map((cv) => [
    cv?.name, cv?.email, cv?.mobile, cv?.pincode, cv?.city, cv?.state,
    cv?.gender, cv?.consultant_profile?.experience, cv?.consultant_profile?.experience,
    cv?.consultant_profile?.dob, cv?.consultant_profile?.designation, cv?.consultant_profile?.websiteUrl,
    cv?.consultant_profile?.region, cv?.consultant_profile?.address,
    cv?.status == 0 ? "In-Active" : "Active"
  ]);
  const exportData = csvHead.concat(csvData);

  const handleVerifiedUser = useCallback(
    (val, id) => {
      if (window.confirm("Are You Sure Want To Update User Verification ?.")) {
        setIsUserVerified((prev) =>
          prev.map((item) => {
            if (item.id === id) {
              return { ...item, isUserVerified: val };
            }
            return item;
          })
        );
        axios
          .put(
            `/api/admin/v1/update-consultant-verified-user/${id}`,
            { isUserVerified: parseInt(val) },
            {
              headers: {
                Authorization: `Bearer ${auth.token}`,
              },
            }

          )
          .then(
            (res) => toast.success(res.data?.message),
          )
          .catch((err) => toast.error(err.response.data?.message));
      }

    },

    [isUserVerified, auth]
  );
  const handlePositionChange = (id, value) => {
    setPositions({
      ...positions,
      [id]: value
    });
  };
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 text-white">Consultants</h5>
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "CONSULTANT" &&
                item?.permission?.permission === "create"
            ) && (
                <Link to="/consultant-add">
                  <Button className="bg-white text-black" size="sm">
                    <span className="me-2">
                      <MdAdd size={22} />
                    </span>
                    Add Consultant
                  </Button>
                </Link>
              )}
          </div>
        </div>
      </div>
      <div
        className="bg-white px-3 py-4 rounded-2  my-4"
        style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}
      >

        <div className="row">



          <div className="c-card-top mb-5 d-flex">
            <div className="col-md-6 text-black">
              <strong>Active Consultants : {activeConsultant}</strong>
            </div>
            <div className="c-card-top-inputs c-card-top-search col-md-5">

              <div className="input-group">
                <Input
                  size={"sm"}
                  value={search}
                  className="c-form-control-serch form-control-sm form-control"
                  placeholder="Search By Name"
                  onChange={({ target: { value } }) => setSearch(value)}
                />
                <div class="input-group-append">
                  <button
                    title="Advance Search"
                    onClick={() => {
                      advanceSearch
                        ? setAdvanceSearch(
                          false
                        )
                        : setAdvanceSearch(
                          true
                        );
                    }}
                    id="c_searchBlock_toggle"
                    class="btn btn-secondary btn-sm c-card-top-block-icon"
                    type="button"
                  >
                    <FiChevronDown />
                  </button>

                </div>
              </div>

              <div
                className={
                  advanceSearch
                    ? "c-card-top-block"
                    : "d-none"
                }
                id="c_searchBlock"
              >
                <div className="c-card-top-block-sec">
                  <div className="c-card-top-block-item">
                    <label>Associate Type</label>
                    <Input
                      size={"sm"}
                      value={categorys}
                      type="select"
                      name="categoryId"

                      onChange={({ target }) => setCategorys(target.value)}
                    >
                      <option value="">Select Category</option>
                      {categories?.map?.((item) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item?.type}
                          </option>
                        );
                      })}
                    </Input>
                  </div>
                  <div className="c-card-top-block-item">
                    <Input
                      size={"sm"}
                      value={subCategorys}
                      type="select"
                      name="subCategoryId"
                      onChange={({ target }) => setSubCategorys(target.value)}
                    >
                      <option value="">Select Segment</option>
                      {subCategories?.map?.((item) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item?.name_english}
                          </option>
                        );
                      })}
                    </Input>
                  </div>
                  <div className="c-card-top-block-item">
                    <Input
                      size={"sm"}
                      value={subSubCategorys}
                      type="select"
                      name="subSubCategoryId"
                      onChange={({ target }) => setSubSubCategorys(target.value)}
                    >
                      <option value="">Select Expertise</option>
                      {subSubCategories?.map?.((item) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item?.name_english}
                          </option>
                        );
                      })}
                    </Input>
                  </div>
                  <div className="c-card-top-block-item">
                    <Input
                      size={"sm"}
                      type="select"
                      name="sort"
                      onChange={e =>
                        setSort(e.target.value)
                      }
                      value={sort}
                    >
                      <option value="asc">
                        Ascending
                      </option>
                      <option value="desc">
                        Descending
                      </option>
                    </Input>

                  </div>

                  <div className="c-card-top-block-item">
                    <label>Start Date</label>

                    <Input
                      type="date"
                      placeholder="Start Date"
                      onChange={e =>
                        setStartDate(
                          e.target.value
                        )
                      }
                      value={startDate}
                    />
                  </div>
                  <div className="c-card-top-block-item">
                    <label>End Date</label>

                    <Input
                      type="date"
                      placeholder="End Date"
                      onChange={e =>
                        setEndDate(
                          e.target.value
                        )
                      }
                      value={endDate}
                    />
                  </div>
                  <div className="c-card-top-block-item">
                    <Input
                      size={"sm"}
                      type="select"
                      name="sort"
                      onChange={e =>
                        setStatusType(
                          e.target.value
                        )
                      }
                      value={statusType}
                    >
                      <option value="">
                        status
                      </option>
                      <option value="1">
                        Active
                      </option>
                      <option value="0">
                        DeActive
                      </option>
                    </Input>

                  </div>

                </div>
              </div>

            </div>
            <div className="col-md-2">
              <CSVLink
                filename={"consultant.csv"}
                className="text-black ms-3"
                title="Export To CSV"
                data={exportData}
              >
                <BiSolidFileExport size={25} />
              </CSVLink>
            </div>
          </div>


          {localLoader ? (
            <div className="d-flex justify-content-center align-items-center mx-auto">
              <Spinner color="primary" size={35} />
            </div>
          ) : (
            <div className="col-md-12">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>SL. No.</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th>Position</th>
                    <th>Is User Verified</th>
                    <th>Online/Offline</th>
                    <th>Internal/External</th>
                    <th>Created Date</th>
                    <th>Status</th>
                    {allowedPermissions?.some?.(
                      (item) =>
                        (item?.permission?.moduleType === "CONSULTANT" &&
                          item?.permission?.permission === "edit") ||
                        item?.permission?.permission === "delete"
                    ) && <th>Action</th>}
                  </tr>
                </thead>

                <tbody>
                  {procedureConsultantList?.map?.((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">{index + 1 + page * paginate}</th>
                        <td>{item?.name} <span>{item?.isUserVerified === 1 ? <MdVerified style={{ color: "11cdef", marginBottom: 5 }} /> : ""}</span></td>
                        <td>{item?.mobile}</td>
                        <td>{item?.email}</td>
                        <td>
                          <Input
                            type="text"
                            value={positions[item.id]}
                            onChange={({ target: { value } }) => handlePositionChange(item.id, value)}
                            onBlur={() => handlePositionUser(item.id)}
                          />
                        </td>

                        <td>
                          <Input
                            type="select"
                            value={
                              isUserVerified.find((it) => it.id === item.id)?.isUserVerified
                            }
                            onChange={({ target: { value } }) =>
                              handleVerifiedUser(value, item.id)
                            }
                          >
                            <option value="1">Verified</option>
                            <option value="0">Not Verified</option>
                          </Input>
                        </td>
                        <td>
                          <Input
                            type="select"
                            value={
                              item?.onlineStatus
                            }
                            onChange={({ target: { value } }) =>
                              handleOnlineChange(value, item.id)
                            }
                          >
                            <option value={1}>Online</option>
                            <option value={0}>Offline</option>
                          </Input>
                        </td>
                        <td>
                          <Input
                            type="select"
                            value={
                              item?.userType
                            }
                            onChange={({ target: { value } }) =>
                              handleUserTypeChange(value, item.id)
                            }
                          >
                            <option value={1}>External</option>
                            <option value={0}>Internal</option>
                          </Input>
                        </td>
                        <td>
                          {item?.createdAt ?
                            new Date(item.createdAt).toLocaleString('en-GB', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                              hour12: true,
                            })
                            : 'N/A'}
                        </td>
                        <td>
                          <Input
                            type="select"
                            value={
                              status.find((it) => it.id === item.id)?.status
                            }
                            onChange={({ target: { value } }) =>
                              handleStatusChange(value, item.id)
                            }
                          >
                            <option value="1">Active</option>
                            <option value="0">Deactivated</option>
                          </Input>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span
                              role="button"
                              onClick={() => {
                                setConsultantDetails(item);
                                setExpertVideo(item);
                                setLockerDetail({ id: item?.id, userType: "consultant" });
                                setConsultantWallet({ id: item?.id, userType: "consultant" });
                                setConsultantCallHistory({ id: item?.id, userType: "consultant" });
                                toggle();
                              }}
                            >
                              <AiOutlineEye size={22} />
                            </span>
                            {allowedPermissions?.some?.(
                              (item) =>
                                item?.permission?.moduleType === "CONSULTANT" &&
                                item?.permission?.permission === "edit"
                            ) && (
                                <Link to={`/consultant-update/${item?.id}`}>
                                  <AiOutlineEdit
                                    size={22}
                                    className="text-warning mx-3"
                                  />
                                </Link>
                              )}
                            {allowedPermissions?.some?.(
                              (item) =>
                                item?.permission?.moduleType === "CONSULTANT" &&
                                item?.permission?.permission === "delete"
                            ) && (
                                <AiOutlineDelete
                                  size={22}
                                  className="text-danger"
                                  onClick={() => handleDel(item.id)}
                                />
                              )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>

        <Modal isOpen={isOpen} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle}> User Details </ModalHeader>
          <ModalBody>
            <div className="row gy-3">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-3">
                    <span className="text-sm fw-semibold text-secondary">
                      Name
                    </span>
                  </div>
                  <div className="col-md-9">
                    <span>{consultantDetails?.name}</span>
                  </div>

                  <div className="col-md-3">
                    <span className="text-sm fw-semibold text-secondary">
                      Mobile
                    </span>
                  </div>
                  <div className="col-md-9">
                    <span>{consultantDetails?.mobile || "N/A"}</span>
                  </div>

                  <div className="col-md-3">
                    <span className="text-sm fw-semibold text-secondary">
                      Email
                    </span>
                  </div>
                  <div className="col-md-9">
                    <span>{consultantDetails?.email || "N/A"}</span>
                  </div>

                  <div className="col-md-3">
                    <span className="text-sm fw-semibold text-secondary">
                      Gender
                    </span>
                  </div>
                  <div className="col-md-9">
                    <span>{consultantDetails?.gender || "N/A"}</span>
                  </div>

                  <div className="col-md-3">
                    <span className="text-sm fw-semibold text-secondary">
                      Audio Fee
                    </span>
                  </div>
                  <div className="col-md-9">
                    <span>
                      {consultantDetails?.profile?.audioFee || "N/A"}
                    </span>
                  </div>

                  <div className="col-md-3">
                    <span className="text-sm fw-semibold text-secondary">
                      Video Fee
                    </span>
                  </div>
                  <div className="col-md-9">
                    <span>
                      {consultantDetails?.profile?.videoFee || "N/A"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="col-md-12">
                  {consultantDetails?.thumbnail ? (
                    <span>
                      <img
                        src={`https://consultancy-iid-new.s3.ap-south-1.amazonaws.com/${consultantDetails?.thumbnail}`}
                        height={100}
                        width={100}
                      />
                    </span>
                  ) : null}
                </div>
              </div>
              {consultantDetails?.expertVideo?.length ? (
                <div className="col-md-12">
                  <div className="py-2 px-4 bg-light mt-3">
                    <span> Expert Video </span>
                  </div>
                  <div className="mt-3">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <td>Category</td>

                          <td>SubCategory</td>
                          <td>SubSubCategory</td>
                          <td>Video</td>
                        </tr>
                      </thead>
                      <tbody>
                        {consultantDetails?.expertVideo?.map((video) => {
                          return (
                            <tr key={video.id}>
                              <td>{video?.category?.type || "N/A"}</td>
                              <td>{video?.subCategory?.name}</td>
                              <td>
                                {video?.subSubCategory?.name || "N/A"}
                              </td>
                              <td>
                                <a
                                  role="button"
                                  className=""
                                  onClick={(e) => handleVideo(e, video.url)}
                                >
                                  View Video
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
              {consultantDetails?.sectors?.length ? (
                <div className="col-12">
                  <div className="py-2 px-4 bg-light mt-3">
                    <span> Consultant Expertise </span>
                  </div>
                  <div className="mt-3">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <td>Category</td>

                          <td>SubCategory</td>
                          <td>SubSubCategory</td>
                        </tr>
                      </thead>
                      <tbody>
                        {consultantDetails?.sectors?.map(
                          (sector) => {
                            return (
                              <tr key={sector.id}>
                                <td>
                                  {sector?.category?.type || "N/A"}
                                </td>
                                <td>{sector?.subCategory?.name_english}</td>
                                <td>
                                  {sector?.subSubCategory?.name_english ||
                                    "N/A"}
                                </td>
                              </tr>
                            );
                          }
                        )}
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}

              {lockerDocs?.lockerGroups?.length ? (
                <div className="col-md-12">
                  <div className="py-2 px-4 bg-light mt-3">
                    <span><b> Locker Groups</b></span>
                  </div>
                  <div className="mt-3">
                    <div className="accordion" id="lockerGroupsAccordion">
                      {lockerDocs?.lockerGroups?.map((lockerGroup, groupIndex) => (
                        <div className="accordion-item" key={groupIndex + 1}>
                          <h2 className="accordion-header" id={`headingGroup${groupIndex + 1}`}>
                            <button
                              className={`accordion-button ${activeIndex === groupIndex + 1 ? '' : 'collapsed'}`}
                              onClick={() => handleToggle(groupIndex + 1)}
                              aria-expanded={activeIndex === groupIndex + 1}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseGroup${groupIndex + 1}`}
                              aria-controls={`collapseGroup${groupIndex + 1}`}
                            >
                              <span className="d-flex me-3 pt-1">
                                {lockerGroup?.groupName == "1" ? (
                                  <>
                                    <span className="icon-box-4 bg-color-4 text-white">
                                      <FaRegBuilding size={22} />
                                    </span>
                                    <span className="ms-2 text-dark mt-2">{lockerGroup?.companyName}</span>
                                  </>
                                ) : (
                                  ""
                                )}

                                {lockerGroup?.groupName == "2" ? (
                                  <>
                                    <span className="icon-box-4 bg-color-7 text-white">
                                      <FaUser size={22} />
                                    </span>
                                    <span className="ms-2 text-dark mt-2">{lockerGroup?.companyName}</span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>
                            </button>
                          </h2>
                          <div
                            id={`collapseGroup${groupIndex + 1}`}
                            className={`accordion-collapse collapse ${activeIndex === groupIndex + 1 ? 'show' : ''}`}
                            aria-labelledby={`headingGroup${groupIndex + 1}`}
                            data-bs-parent="#lockerGroupsAccordion"
                          >
                            <div className="accordion-body">
                              {lockerGroup?.lockers?.map((lockerDoc, lockerIndex) => (
                                <div className="accordion" id={`lockersAccordion${groupIndex + 1}`} key={lockerIndex + 1}>
                                  <div className="accordion-item mb-3">
                                    <h2 className="accordion-header" id={`headingLocker${groupIndex + 1}-${lockerIndex + 1}`}>
                                      <button
                                        className={`accordion-button ${activeFolderIndex === lockerIndex + 1 ? '' : 'collapsed'}`}
                                        onClick={() => handleFolderToggle(lockerIndex + 1)}
                                        aria-expanded={activeFolderIndex === lockerIndex + 1}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapseLocker${groupIndex + 1}-${lockerIndex + 1}`}
                                        aria-controls={`collapseLocker${groupIndex + 1}-${lockerIndex + 1}`}
                                      >
                                        <FcOpenedFolder size={22} className="me-2" /> {lockerDoc.documentName}
                                      </button>
                                    </h2>
                                    <div
                                      id={`collapseLocker${groupIndex + 1}-${lockerIndex + 1}`}
                                      className={`accordion-collapse collapse ${activeFolderIndex === lockerIndex + 1 ? 'show' : ''}`}
                                      aria-labelledby={`headingLocker${groupIndex + 1}-${lockerIndex + 1}`}
                                      data-bs-parent={`#lockersAccordion${groupIndex + 1}`}
                                    >
                                      <div className="accordion-body">
                                        <table className="table table-striped">
                                          <thead>
                                            <tr>
                                              <td>Locker Id</td>
                                              <td>Document</td>
                                              <td>Download</td>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {lockerDoc?.lockerDocuments?.map((lockerFile) => (
                                              <tr key={lockerFile.id}>
                                                <td>{lockerFile.lockerId || "N/A"}</td>
                                                <td>{lockerFile.documentPath || "N/A"}</td>
                                                <td> <a href={`https://consultancy-iid-new.s3.ap-south-1.amazonaws.com/${lockerFile.documentPath}`}><FaDownload className="me-2" /> Download </a></td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="col-md-12">
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className={`btn ${history ? 'btn-outline-warning' : 'btn-warning'}`} onClick={() => setHistory(false)}> Call History</h3>
                  <h3 className={`btn ${history ? 'btn-warning' : 'btn-outline-warning'}`}
                    onClick={() => setHistory(true)}> Wallet History</h3>
                </div>
              </div>
              {history ? (<div className="col-md-12">
                <div>
                  <h5 className="mb-0">Wallet Amount</h5>
                  <div className="mt-2">
                    <div className="row">
                      <div className="col-md-12 d-flex justify-content-center">
                        <div className="p-2 rounded" style={{ background: "#007bff", color: "#fff", display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%' }}>
                          <span className="text-white"><b>Wallet Balance :</b>{walletAmount?.creditAmount - walletAmount?.debitAmount} </span>
                        </div>
                        <span className="text-white"> <CSVLink
                          data={exportData}
                          filename={`wallet-details-${moment().format('YYYY-MM-DD')}.csv`}
                          className="btn btn-outline-primary"
                          target="_blank">
                          <FaDownload /> Export
                        </CSVLink></span>

                      </div>
                    </div>
                    <h6 className="mt-4">Transaction Details</h6>
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Transaction Amount</th>
                            <th>Transaction Type</th>
                            <th>Transaction Detail</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.length ? (
                            currentItems.map((item, index) => (
                              <tr key={index}>
                                <td>{item.transaction_amount}</td>
                                <td>{item.transaction_type}</td>
                                <td>{item.transaction_detail}</td>
                                <td>
                                  {item?.createdAt ?
                                    new Date(item.createdAt).toLocaleString('en-GB', {
                                      day: '2-digit',
                                      month: '2-digit',
                                      year: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      second: '2-digit',
                                      hour12: true,
                                    })
                                    : 'N/A'}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3" className="text-center">No transactions found.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* Pagination */}
                      <div className="d-flex justify-content-between">
                        <button
                          onClick={() => goToPage(Math.max(0, currentPage - 1))}
                          disabled={currentPage === 0}
                          className="btn btn-secondary"
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => goToPage(Math.min(pageCount - 1, currentPage + 1))}
                          disabled={currentPage === pageCount - 1}
                          className="btn btn-secondary"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>) : (<div className="col-md-12">
                <div className="mt-2">
                  <div className="row">
                    <div className="col-md-12 d-flex justify-content-center">
                      <div className="p-2 rounded" style={{ background: "#007bff", color: "#fff", display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
                        <span className="text-white"><b>Call History</b></span>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive mt-5">
                    {callHistory.length ? (
                      <div className="">
                        {callHistory?.map((item) => {
                          return (
                            <>
                              <div key={item.id} className="d-flex hover-class border align-items-center justify-content-between py-3 px-4">
                                <div className="col-md-6 col-8">
                                  <div className="d-flex align-items-center">
                                    <p className="mb-0 fs-16 text-capitalize">{item.status}</p>
                                    <span className="text-secondary small">
                                      {moment(item.createdAt).format(
                                        "MMMM Do YYYY, hh:mm:ss A"
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="text-end col-md-3 col-4">
                                  <span className="small">Booking Id  {item.bookingId} </span> <br />
                                  <span className="text-muted"> Duration: {getDuration(item.start_time, item.end_time)}</span>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="py-5">
                        <p className="text-center mb-0 text-dark">
                          Sorry, you dont have any calls
                        </p>
                      </div>
                    )}

                  </div>
                </div>

              </div>)}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Close
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
          <ModalBody className="p-0">
            <ReactPlayer url={url} playing width="100%" controls={true} />
          </ModalBody>
        </Modal>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <select
              id="paginate"
              onChange={(e) => setPaginate(e.target.value)}
              value={paginate}
              className="paging custom-select custom-select-page"
            >
              <option value="10" selected>
                10
              </option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
            <div className="mx-3 py-2 text-black">
              Total Results : {totalConsultant}
            </div>
          </div>
          <Pagination
            pageCount={totalConsultant / paginate}
            onChange={(e) => setPage(e.selected)}
          />
        </div>
      </div>
    </div>
  );
};

export default ConsultantList;
