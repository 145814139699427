const initialState = {
    loading: false,
    error: {},
    edpCourseTypes: [],
    edpCourseTypesCount: "",
    edpCourseCategories: [],
    edpCourseCategoriesCount: "",
    edpCoursetype: [],
    edpCourseSubCategories: [],
    edpCourseSubCategoriesCount: "",
    edpCourseSubSubCategories: [],
    edpCourseSubSubCategoriesCount: "",
    edpCourseSectionModule: [],
    edpCourseSectionModuleList: [],
    edpCourseModule: [],
    edpCourseOrder: [],
    edpCourseCount: 0,
    edpCourseModuleCount: 0,
    edpCourseModuleSectionCount: 0,
    edpCourses: [],
    edpCoursesCount: "",
    edpCourseQuizes: [],
    edpCourseQuizCount: "",
    allCourses: [],
};

const EdpCourseReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_EDP_COURSETYPE_PENDING":
        case "GET_EDP_COURSE_CATEGORY_PENDING":
        case "GET_EDP_COURSE_TYPE_LIST_PENDING":
        case "GET_EDP_COURSE_SUB_CATEGORY_PENDING":
        case "GET_EDP_COURSE_SUB_SUB_CATEGORY_PENDING":
        case "GET_EDP_COURSE_SECTION_MODULE_PENDING":
        case "GET_EDP_COURSE_SECTION_MODULE_LIST_PENDING":
        case "GET_EDP_COURSE_MODULE_PENDING":
        case "GET_EDP_COURSE_ORDER_PENDING":
        case "GET_EDP_COURSE_LIST_PENDING":
        case "GET_EDP_COURSE_PENDING":
        case "GET_EDP_COURSE_QUIZ_LIST_PENDING":
        case "GET_EDP_COURSE_QUIZ_QUESTION_PENDING":
            return { ...state, loading: true };

        case "GET_EDP_COURSETYPE_FULFILLED":
            return {
                ...state,
                loading: false,
                edpCourseTypes: action.payload,
                edpCourseTypesCount: action.edpCourseTypesCount,
            };

        case "GET_EDP_COURSE_CATEGORY_FULFILLED":
            return {
                ...state,
                loading: false,
                edpCourseCategories: action.payload,
                edpCourseCategoriesCount: action.edpCourseCategoriesCount,
            };
        case "GET_EDP_COURSE_TYPE_LIST_FULFILLED":
            return {
                ...state,
                loading: false,
                edpCoursetype: action.payload?.data,
            };
        case "GET_EDP_COURSE_SUB_CATEGORY_FULFILLED":
            return {
                ...state,
                loading: false,
                edpCourseSubCategories: action.payload,
                edpCourseSubCategoriesCount: action.edpCourseSubCategoriesCount,
            };
        case "GET_EDP_COURSE_SUB_SUB_CATEGORY_FULFILLED":
            return {
                ...state,
                loading: false,
                edpCourseSubSubCategories: action.payload,
                edpCourseSubSubCategoriesCount: action.edpCourseSubSubCategoriesCount,
            };

        case "GET_EDP_COURSE_SECTION_MODULE_FULFILLED":
            return {
                ...state,
                loading: false,
                edpCourseSectionModule: action.payload?.data,
                edpCourseModuleSectionCount: action.edpCourseModuleSectionCount,
            };
        case "GET_EDP_COURSE_SECTION_MODULE_LIST_FULFILLED":
            return {
                ...state,
                loading: false,
                edpCourseSectionModuleList: action.payload?.data,
            };
        case "GET_EDP_COURSE_MODULE_FULFILLED":
            return {
                ...state,
                loading: false,
                edpCourseModule: action.payload?.data,
                edpCourseModuleCount: action.payload?.count,
            };
        case "GET_EDP_COURSE_ORDER_FULFILLED":
            return {
                ...state,
                loading: false,
                edpCourseOrder: action.payload?.data,
                edpCourseCount: action.payload?.count?.count,
            };
        case "GET_EDP_COURSE_LIST_FULFILLED":
            return {
                ...state,
                loading: false,
                edpCourses: action.payload,
                edpCoursesCount: action.coursesCount,
            };
        case "GET_EDP_COURSE_QUIZ_LIST_FULFILLED":
            return {
                ...state,
                loading: false,
                edpCourseQuizes: action.payload,
                edpCourseQuizCount: action.edpCourseQuizCount,
            };
        case "GET_EDP_COURSE_FULFILLED":
            return {
                ...state,
                loading: false,
                allCourses: action.payload,
            };
        case "GET_EDP_COURSE_QUIZ_QUESTION_FULFILLED":
            return {
                ...state,
                loading: false,
                CourseQuizQuestions: action.payload,
                CourseQuizQuestionsCount: action.CourseQuizQuestionsCount,
            };
        case "GET_EDP_COURSETYPE_FAILED":
        case "GET_EDP_COURSE_CATEGORY_FAILED":
        case "GET_EDP_COURSE_TYPE_LIST_FAILED":
        case "GET_EDP_COURSE_SUB_CATEGORY_FAILED":
        case "GET_EDP_COURSE_SUB_SUB_CATEGORY_FAILED":
        case "GET_EDP_COURSE_SECTION_MODULE_FAILED":
        case "GET_EDP_COURSE_SECTION_MODULE_LIST_FAILED":
        case "GET_EDP_COURSE_MODULE_FAILED":
        case "GET_EDP_COURSE_ORDER_FAILED":
        case "GET_EDP_COURSE_LIST_REJECTED":
        case "GET_EDP_COURSE_REJECTED":
        case "GET_EDP_COURSE_QUIZ_LIST_REJECTED":
        case "GET_EDP_COURSE_QUIZ_QUESTION_REJECTED":
            return { ...state, loading: false, error: action.payload };

        default:
            return { ...state };
    }
};

export default EdpCourseReducer;
