import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, FormGroup, Button } from "reactstrap";
import { getEdpCourseCatgeory, getEdpCourseSubCatgeory } from "../../redux/actions/EdpCourseActions";
const EditEdpSubCategory = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const {
        AuthReducer: { auth },
        EdpCourseReducer: { edpCourseCategories },
    } = useSelector((state) => state);
    const [formData, setFormData] = useState({
        category_id: "",
        name: "",
        hindi_name: "",
        title: "",
        hindi_title: "",
        description: "",
        short_description: "",
        hindi_description: "",
        price: 0,
        status: 1,
        type: "Free",
        image: "",
        url: "",
    });
    const [errors, SetErrors] = useState({});
    const navigate = useNavigate();
    const inputHandler = (e) => {
        const { name, value } = e.target;
        if (name === "image") {
            setFormData({ ...formData, [name]: e.target.files[0] });
        }
        if (name === "status") {
            setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    useEffect(() => {
        dispatch(getEdpCourseCatgeory(auth.token));
    }, [auth.token]);

    useEffect(() => {
        dispatch(getEdpCourseSubCatgeory(auth.token));
    }, [auth]);

    useEffect(() => {
        if (id) {
            axios(`/api/admin/v1/edp-sub-category-edit/${id}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            }).then((res) => {
                setFormData((prev) => {
                    return {
                        ...prev,
                        name: res.data?.edpCourseSubCategories?.name,
                        hindi_name: res.data?.edpCourseSubCategories?.hindi_name,
                        category_id: res.data?.edpCourseSubCategories?.category_id,
                        title: res.data?.edpCourseSubCategories?.title,
                        url: res.data?.edpCourseSubCategories?.url,
                        hindi_title: res.data?.edpCourseSubCategories?.hindi_title,
                        description: res.data?.edpCourseSubCategories?.description,
                        short_description: res.data?.edpCourseSubCategories?.short_description,
                        hindi_description: res.data?.edpCourseSubCategories?.hindi_description,
                        price: res.data?.edpCourseSubCategories?.price,
                        type: res.data?.edpCourseSubCategories?.type,
                        status: res.data?.edpCourseSubCategories?.status,
                    };
                });
            }).catch((err) => console.log(err));
        }
    }, [id, auth]);

    const handleSubmit = () => {
        const form = new FormData();
        for (const key in formData) {
            form.append([key], formData[key]);
        }
        axios.put(`/api/admin/v1/edp-sub-category/${id}`, form, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
            },
        }).then((res) => {
            toast.success(res.data?.message);
            navigate(-1);
        }).catch((err) => {
            SetErrors(err.response.data);
            toast.error(err.response.data);
        });
    };
    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <h5 className="text-white">Edit EDP Sub Category</h5>
                </div>
            </div>
            <div className="bg-white px-3 py-4 rounded-2  my-4">
                <div className="row">
                    <div className="col-md-8 offset-md-1">
                        <h4 className="text-start mb-5">Update EDP Sub Catgeory</h4>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>EDP Category <span className="text-danger fs-4"> *</span></Label>
                            <Input className="text-black" value={formData?.category_id} type="select" name="category_id" onChange={inputHandler}>
                                <option value="">Select</option>
                                {edpCourseCategories?.map?.((item) => {
                                    return (<option value={item.id} key={item.id}>{item?.name}</option>);
                                })}
                            </Input>
                            <span className="d-block text-danger">{" "}{errors?.category_id}{" "}</span>
                        </FormGroup>
                    </div>

                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Title English <span className="text-danger fs-4"> *</span></Label>
                            <Input value={formData?.title} name="title" onChange={inputHandler} />
                            <span className="d-block text-danger">{" "}{errors?.title}{" "}</span>
                        </FormGroup>
                    </div>

                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Title Hindi</Label>
                            <Input value={formData?.hindi_title} name="hindi_title" onChange={inputHandler} />
                        </FormGroup>
                    </div>

                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>English Name <span className="text-danger fs-4">*</span></Label>
                            <Input value={formData?.name} name="name" invalid={errors?.name} onChange={inputHandler} />
                            <span className="d-block text-danger">{" "}{errors?.name}{" "}</span>
                        </FormGroup>
                    </div>

                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Hindi Name</Label>
                            <Input value={formData?.hindi_name} name="hindi_name" onChange={inputHandler} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Short Hindi Description</Label>
                            <Input name="short_description" value={formData?.short_description} type="textarea" onChange={inputHandler} />
                        </FormGroup>
                    </div>

                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Thumbnail</Label>
                            <Input type="file" name="image" onChange={(e) =>
                                setFormData((prev) => ({
                                    ...prev,
                                    image: e.target.files[0],
                                }))}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label> Description</Label>
                            <Input name="description" value={formData?.description} type="textarea" onChange={inputHandler} />
                        </FormGroup>
                    </div>

                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Hindi Description</Label>
                            <Input value={formData?.hindi_description} name="hindi_description" type="textarea" onChange={inputHandler} />
                        </FormGroup>
                    </div>

                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Url</Label>
                            <Input value={formData?.url} name="url" onChange={inputHandler} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup switch>
                            <Input type="switch" checked={formData?.status} onChange={inputHandler} name="status" />
                            <Label check>Status</Label>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label className="text-black">Select Type</Label>
                            <Input
                                value={formData?.type}
                                type="select"
                                name="type"
                                onChange={inputHandler}
                            >
                                <option value="">Select</option>
                                <option value="Free">Free</option>
                                <option value="Paid">Paid</option>
                            </Input>
                        </FormGroup>
                    </div>

                    {formData.type === "Paid" ? (
                        <div className="col-md-4 offset-md-1">
                            <FormGroup>
                                <Label className="text-black">Amount</Label>
                                <Input
                                    value={formData?.price}
                                    name="price"
                                    onChange={inputHandler}
                                />
                            </FormGroup>
                        </div>
                    ) : null}
                    <div className="col-md-4 offset-md-4">
                        <Button color="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                        <Button className="ms-3" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditEdpSubCategory;
