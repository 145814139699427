import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { MdAdd } from "react-icons/md";
import { Spinner } from "reactstrap";
import {
  getSubSubCategories,
  getCategories,
  getSubCategories,
} from "../../redux/actions/indexActions";
import Pagination from "../../Components/Paginate";
import { toast } from "react-toastify";
import { Input, Label, FormGroup } from "reactstrap";
import axios from "axios";
import { CSVLink } from "react-csv";
import { BiSolidFileExport } from "react-icons/bi";
const Index = () => {
  const {
    AuthReducer: { auth },
    indexReducer: {
      subSubCategories,
      categories,
      loading,
      subSubCategoryCount,
    },
    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("desc");
  const [paginate, setPaginate] = useState(10);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subCategoryFilter, setSubCategoryFilter] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [positions, setPositions] = useState({});
  const dispatch = useDispatch();
  const handleDel = (id) => {
    if (window.confirm("Are You Sure You Want To Delete This SubSubCategory")) {
      axios.delete(`/api/admin/v1/sub/sub/category/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
        .then((res) => {
          toast.success(res.data.message);
          dispatch(
            getSubSubCategories(
              auth?.token,
              10,
              page,
              search,
              paginate,
              categoryFilter,
              subCategoryFilter,
              sort,
              startDate,
              endDate
            )
          );
        }).catch((err) => toast.error("Somthing Went Wrong"));
    }
  };

  useEffect(() => {
    dispatch(getCategories(auth.token));
    //dispatch(getSubCategories(auth.token));
  }, [auth]);
  useEffect(() => {
    dispatch(
      getSubSubCategories(
        auth?.token,
        10,
        page,
        search,
        paginate,
        categoryFilter,
        subCategoryFilter,
        sort,
        startDate,
        endDate
      )
    );
  }, [auth, page, search, paginate, categoryFilter, subCategoryFilter, sort, startDate, endDate]);
  useEffect(() => {
    if (categoryFilter) {
      // setFormData((prev) => ({ ...prev, subCategoryId: "" }));
      axios(`/api/web/v1/get-sectorBy-type/${categoryFilter}`).then(({ data }) => {
        setSubCategories(data?.data);
      }).catch((err) => console.error(err));
    } else {
      setSubCategories([]);
    }
  }, [categoryFilter]);

  const csvHead = [["category", "sector", "name", "description"]];
  const csvData = subSubCategories.map((cv) => [
    cv?.["category-type"]?.type,
    cv?.subCategory?.name_english,
    cv?.name_english,
    cv?.description_english,
  ]);
  const exportData = csvHead.concat(csvData);
  useEffect(() => {
    if (subSubCategories) {
      const initialPositions = {};
      subSubCategories.forEach(subSubCategory => {
        initialPositions[subSubCategory.id] = subSubCategory.position;
      });
      setPositions(initialPositions);
    }
  }, [subSubCategories]);
  const handlePosition = useCallback((id) => {
    axios.put(
      `/api/admin/v1/update-subsubcategory-position/${id}`,
      { position: parseInt(positions[id]) },
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    ).then((res) => {
      toast.success(res.data?.message);
      dispatch(
        getSubSubCategories(
          auth?.token,
          10,
          page,
          search,
          paginate,
          categoryFilter,
          subCategoryFilter,
          sort,
          startDate,
          endDate
        )
      );
    }).catch((err) => toast.error(err.response.data?.message));
  }, [auth, positions, dispatch, categoryFilter, subCategoryFilter, paginate, page, search, sort, startDate, endDate]);
  const handlePositionChange = (id, value) => {
    setPositions({
      ...positions,
      [id]: value
    });
  };
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 text-white">Expertise</h5>
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "SUBSUBCATEGORIES" &&
                item?.permission?.permission === "create"
            ) ? (
              <Link to="/add-sub-sub-category">
                <Button className="bg-white text-black" size="sm">
                  <span className="me-2">
                    <MdAdd size={22} />
                  </span>
                  Add Expertise
                </Button>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4" style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}>
        <div className="row">
          <div className="col-md-11">
            <div className="row">
              <div className="col-md-2 mb-2">
                <Input size={"sm"} value={search} placeholder="Search By Name" onChange={({ target: { value } }) => setSearch(value)} />
              </div>
              <div class="col-md-2 mb-2">
                <Input size={"sm"} value={categoryFilter} type="select" name="categoryId" onChange={({ target }) => setCategoryFilter(target.value)}>
                  <option value="">Select Category</option>
                  {categories?.map?.((item) => {
                    return (
                      <option value={item.id} key={item.id}>{item?.type}</option>
                    );
                  })}
                </Input>
              </div>
              <div class="col-md-2">
                <Input size={"sm"} value={subCategoryFilter} type="select" name="subCategoryId" onChange={({ target }) => setSubCategoryFilter(target.value)}>
                  <option value="">Select Expertise</option>
                  {subCategories?.map?.((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item?.name_english}
                      </option>
                    );
                  })}
                </Input>
              </div>
              <div className="col-md-2 mb-2">
                <Input type="date" size="sm" value={startDate} placeholder="Start Date" onChange={({ target: { value } }) => setStartDate(value)} />
              </div>
              <div className="col-md-2 mb-2">
                <Input type="date" size="sm" value={endDate} placeholder="End Date" onChange={({ target: { value } }) => setEndDate(value)} />
              </div>
             
              <div className={`col-md-2`}>
                <Input type="select" size={"sm"} value={sort} onChange={({ target }) => setSort(target.value)}>
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending</option>
                </Input>
              </div>
              <div className="col-md-2 mb-2 ">
                <button className="btn btn-primary btn-sm me-2 text-white" onClick={() => { setSearch(""); setStartDate(""); setEndDate(""); }}> Clear </button>
              </div>
            </div>
          </div>
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "SUBSUBCATEGORIES" &&
              item?.permission?.permission === "export"
          ) && (
              <div className="col-md-1">
                <CSVLink
                  filename={"sub-sub-categories.csv"}
                  className="text-black ms-3"
                  title="Export To CSV"
                  data={exportData}
                >
                  <BiSolidFileExport size={25} />
                </CSVLink>
              </div>
            )}
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center mx-auto">
            <Spinner color="primary" />
          </div>
        ) : (
          <div className="col-md-12">
            <Table hover responsive>
              <thead>
                <tr>
                  <th>SL. No.</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Category</th>
                  <th>Segment</th>
                  <th>Position</th>
                  {allowedPermissions?.some?.(
                    (item) =>
                      item?.permission?.moduleType === "SUBSUBCATEGORIES" &&
                      (item?.permission?.permission === "edit" ||
                        item?.permission?.permission === "delete")
                  ) && <th>Action</th>}
                </tr>
              </thead>
              <tbody>
                {(subSubCategories ? subSubCategories : [])?.map?.(
                  (item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">{index + 1 + page * 10}</th>
                        <td>{item?.name_english}</td>
                        <td>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                item?.short_description_english?.slice(0, 200) +
                                "...",
                            }}
                          />
                        </td>
                        <td>{item?.["category-type"]?.type}</td>

                        <td>{item?.subCategory?.name_english}</td>
                        <td>
                          <Input
                            type="text"
                            value={positions[item.id]}
                            onChange={({ target: { value } }) => handlePositionChange(item.id, value)}
                            onBlur={() => handlePosition(item.id)}
                          />
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {allowedPermissions?.some?.(
                              (item) =>
                                item?.permission?.moduleType ===
                                "SUBSUBCATEGORIES" &&
                                item?.permission?.permission === "edit"
                            ) && (
                                <Link to={`/update-sub-sub-category/${item?.id}`}>
                                  <AiOutlineEdit
                                    size={22}
                                    className="text-warning me-3"
                                  />
                                </Link>
                              )}
                            {allowedPermissions?.some?.(
                              (item) =>
                                item?.permission?.moduleType ===
                                "SUBSUBCATEGORIES" &&
                                item?.permission?.permission === "delete"
                            ) && (
                                <AiOutlineDelete
                                  size={22}
                                  className="text-danger"
                                  onClick={() => handleDel(item.id)}
                                />
                              )}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <select
              id="paginate"
              onChange={(e) => setPaginate(e.target.value)}
              value={paginate}
              className="paging custom-select custom-select-page"
            >
              <option value="10" selected>
                10
              </option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
            <div className="mx-3 py-2 text-black">
              Total Results : {subSubCategoryCount}
            </div>
          </div>
          <Pagination
            pageCount={subSubCategoryCount / paginate}
            pageRange={3}
            onChange={(e) => setPage(e.selected)}
          />
        </div>
      </div>
    </div>
  );
};

export default Index;
