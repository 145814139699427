import axios from "axios";



export const getEdpCourseCatgeory =
    (token, limit, page, search = "", sort = "asc") =>
        async (dispatch) => {
            try {
                dispatch({ type: "GET_EDP_COURSE_CATEGORY_PENDING" });
                const response = await axios(
                    `/api/admin/v1/edp-category-list?limit=${limit || 10}&page=${page || 0
                    }&search=${search}&sort=${sort}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                dispatch({
                    type: "GET_EDP_COURSE_CATEGORY_FULFILLED",
                    payload: response.data.edpCourseCategories,
                    edpCourseCategoriesCount: response.data?.edpCourseCategoriesCount
                });
            } catch (error) {
                dispatch({ type: "GET_EDP_COURSE_CATEGORY_FAILED", payload: error.response.data });
            }
        };

export const getEdpCourseTypes = (token) => async (dispatch) => {
    try {
        dispatch({ type: "GET_EDP_COURSE_TYPE_LIST_PENDING" });
        const response = await axios("/api/mobile/v1/coure-types", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: "GET_EDP_COURSE_TYPE_LIST_FULFILLED",
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: "GET_EDP_COURSE_TYPE_LIST_REJECTED",
            payload: error.response.data,
        });
    }
};

export const getEdpCourseSubCatgeory = (token, limit, page, search = "", sort = "desc") =>
    async (dispatch) => {
        try {
            dispatch({ type: "GET_EDP_COURSE_SUB_CATEGORY_PENDING" });
            const response = await axios(`/api/admin/v1/edp-sub-category-list?limit=${limit || 10}&page=${page || 0
                }&search=${search}&sort=${sort}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            dispatch({
                type: "GET_EDP_COURSE_SUB_CATEGORY_FULFILLED",
                payload: response.data.edpCourseSubCategories,
                edpCourseSubCategoriesCount: response.data?.edpCourseSubCategoriesCount
            });
        } catch (error) {
            dispatch({ type: "GET_EDP_COURSE_SUB_CATEGORY_FAILED", payload: error.response.data });
        }
    };

export const getEdpCourseSubSubCategory = (token, limit, page, search = "", sort = "asc") =>
    async (dispatch) => {
        try {
            dispatch({ type: "GET_EDP_COURSE_SUB_SUB_CATEGORY_PENDING" });
            const response = await axios(
                `/api/admin/v1/edp-sub-sub-category-list?limit=${limit || 10}&page=${page || 0
                }&search=${search}&sort=${sort}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch({
                type: "GET_EDP_COURSE_SUB_SUB_CATEGORY_FULFILLED",
                payload: response.data.edpCourseSubSubCategories,
                edpCourseSubSubCategoriesCount: response.data?.edpCourseSubSubCategoriesCount
            });
        } catch (error) {
            dispatch({ type: "GET_EDP_COURSE_SUB_SUB_CATEGORY_FAILED", payload: error.response.data });
        }
    };

export const getEdpCourseSubCategoryList = (token, id) => async (dispatch) => {
    try {
        dispatch({ type: "GET_EDP_COURSE_SUB_CATEGORY_LIST_PENDING" });
        const response = await axios(`/api/mobile/v1/get-edp-sub-category-list/id=${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: "GET_EDP_COURSE_SUB_CATEGORY_LIST_FULFILLED",
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: "GET_EDP_COURSE_SUB_CATEGORY_LIST_REJECTED",
            payload: error.response.data,
        });
    }
};

export const getEdpCourseCategories = (token) => async (dispatch) => {
    try {
        dispatch({ type: "GET_EDP_COURSE_CATEGORIES_LIST_PENDING" });
        const response = await axios("/api/mobile/v1/get-course-category-list", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: "GET_EDP_COURSE_CATEGORIES_LIST_FULFILLED",
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: "GET_EDP_COURSE_CATEGORIES_LIST_REJECTED",
            payload: error.response.data,
        });
    }
};

export const getEdpCourseSectionModule = (token, limit, page, search = "", sort = "desc") => async (dispatch) => {
    try {
        dispatch({ type: "GET_EDP_COURSE_SECTION_MODULE_PENDING" });
        const response = await axios(
            `/api/admin/v1/course-module-section-list?limit=${limit || 10}&page=${page || 0
            }&search=${search}&sort=${sort}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: "GET_EDP_COURSE_SECTION_MODULE_FULFILLED",
            payload: response.data,
            courseModuleSectionCount: response.data.courseModuleSectionCount,
        });
    } catch (error) {
        dispatch({
            type: "GET_EDP_COURSE_SECTION_MODULE_REJECTED",
            payload: error.response.data,
        });
    }
};

export const getEdpCourseSectionModuleList = (token) => async (dispatch) => {
    try {
        dispatch({ type: "GET_EDP_COURSE_SECTION_MODULE_LIST_PENDING" });
        const response = await axios("/api/mobile/v1/course-list", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: "GET_EDP_COURSE_SECTION_MODULE_LIST_FULFILLED",
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: "GET_EDP_COURSE_SECTION_MODULE_LIST_REJECTED",
            payload: error.response.data,
        });
    }
};

export const getEdpCourseModule = (token, limit, page, search = "", sort = "desc") => async (dispatch) => {
    try {
        dispatch({ type: "GET_EDP_COURSE_MODULE_PENDING" });
        const response = await axios(
            `/api/admin/v1/course-module-list?limit=${limit || 10}&page=${page || 0}&search=${search}&sort=${sort}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: "GET_EDP_COURSE_MODULE_FULFILLED",
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: "GET_EDP_COURSE_MODULE_REJECTED",
            payload: error.response.data,
        });
    }
};

export const getEdpCourseOrder = (token, limit, page, search = "", sort = "desc", course) => async (dispatch) => {
    try {
        dispatch({ type: "GET_EDP_COURSE_ORDER_PENDING" });
        const response = await axios(
            `/api/admin/v1/all-course-order?limit=${limit || 10}&page=${page || 0}&search=${search}&sort=${sort}&course=${course}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: "GET_EDP_COURSE_ORDER_FULFILLED",
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: "GET_EDP_COURSE_ORDER_REJECTED",
            payload: error.response.data,
        });
    }
};

export const getAllEdpCourses = (token, limit, page, search = "", sort = "desc") => async (dispatch) => {
    try {
        dispatch({ type: "GET_EDP_COURSE_LIST_PENDING" });
        const response = await axios(`/api/admin/v1/edp-course-list?limit=${limit || 10}&page=${page || 0}&search=${search}&sort=${sort}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({ type: "GET_EDP_COURSE_LIST_FULFILLED", payload: response.data.edpCourses, edpCoursesCount: response.data?.edpCoursesCount });
    } catch (error) {
        dispatch({
            type: "GET_EDP_COURSE_LIST_REJECTED",
            payload: error.response.data,
        });
    }
};


export const getEdpCourses = (token) => async (dispatch) => {
    try {
        dispatch({ type: "GET_EDP_COURSE_PENDING" });
        const response = await axios("/api/admin/v1/get-all-courses", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: "GET_EDP_COURSE_FULFILLED",
            payload: response.data.courses
        });
    } catch (error) {
        dispatch({
            type: "GET_EDP_COURSE_REJECTED",
            payload: error.response.data,
        });
    }
};

export const getEdpSubSubCategory = (token, id) => async (dispatch) => {
    try {
        dispatch({ type: "GET_EDP_COURSE_SUB_CATEGORY_LIST_PENDING" });
        const response = await axios(`/api/admin/v1/get-edp-sub-category-list/id=${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: "GET_EDP_COURSE_SUB_CATEGORY_LIST_FULFILLED",
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: "GET_EDP_COURSE_SUB_CATEGORY_LIST_REJECTED",
            payload: error.response.data,
        });
    }
};