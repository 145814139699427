import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, FormGroup, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { getEdpCourseTypes } from "../../redux/actions/EdpCourseActions";

const AddEdpCategory = () => {
    const [formData, setFormData] = useState({
        name: "",
        hindi_name: "",
        short_description: "",
        image: "",
        status: 1,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { AuthReducer: { auth } } = useSelector((state) => state);
    const { CourseReducer: { coursetype } } = useSelector((state) => state);
    const [errors, SetErrors] = useState({});
    useEffect(() => { dispatch(getEdpCourseTypes(auth.token)) }, [auth.token]);

    const inputHandler = (e) => {
        const { name, value } = e.target;
        if (name === "image") {
            setFormData({ ...formData, [name]: e.target.files[0] });
        } else if (name === "status") {
            setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = () => {
        const form = new FormData();
        for (const key in formData) {
            form.append([key], formData[key]);
        }
        axios.post(`/api/admin/v1/add-edp-category`, form, {
            headers: { Authorization: `Bearer ${auth?.token}` },
        }).then((res) => {
            toast.success(res.data?.message);
            navigate(-1);
        }).catch((err) => {
            toast.error(err.response.data);
            SetErrors(err.response.data.errors);
        });
    }

    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12"><h5 className="text-white">Add Edp Category</h5></div>
            </div>
            <div className="bg-white px-3 py-4 rounded-2  my-4">
                <div className="row">

                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label> English Name <span className="text-danger fs-4">*</span></Label>
                            <Input value={formData?.name} name="name" type="text" invalid={errors?.name} onChange={inputHandler} />
                            <span className="d-block text-danger"> {errors?.name} </span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Hindi Name</Label>
                            <Input value={formData?.hindi_name} name="hindi_name" type="text" onChange={inputHandler} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Short Description</Label>
                            <Input name="short_description" value={formData?.short_description} type="textarea" onChange={inputHandler} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Upload Picture <span className="text-danger fs-4">*</span></Label>
                            <Input type="file" name="image" onChange={(e) =>
                                setFormData((prev) => ({
                                    ...prev,
                                    image: e.target.files[0],
                                }))
                            } />
                            <span className="d-block text-danger"> {errors?.image} </span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup switch>
                            <Input type="switch" checked={formData?.status} onChange={inputHandler} name="status" />
                            <Label check>Status</Label>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-4">
                        <Button color="primary" onClick={handleSubmit}>Submit</Button>
                        <Button className="ms-3" onClick={() => navigate(-1)}>Cancel</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEdpCategory;
