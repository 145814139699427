import React, { useEffect, useState } from "react";
import { Input, FormGroup, Label, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getEdpCourseCatgeory, getEdpCourseSubCatgeory } from "../../redux/actions/EdpCourseActions";
const AddEdpSubSubCategory = () => {
    const [formData, setFormData] = useState({
        category_id: "",
        sub_category_id: "",
        name: "",
        hindi_name: "",
        title: "",
        hindi_title: "",
        description: "",
        short_description: "",
        hindi_description: "",
        price: 0,
        status: 1,
        image: "",
        type: "Free",
        url: "",
    });
    const [errors, setErrors] = useState({});
    const [subCategories, setSubCategories] = useState([]);
    const [EdpCourseSubCategory, setEdpCourseSubCategory] = useState([]);
    const {
        AuthReducer: { auth },
        EdpCourseReducer: { edpCourseCategories, edpCourseSubCategories },
    } = useSelector((state) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const inputHandler = (e) => {
        const { name, value } = e.target;
        if (name === "image") {
            setFormData({ ...formData, [name]: e.target.files[0] });
        }
        if (name === "status") {
            setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    const handleSubmit = () => {
        const form = new FormData();
        for (const key in formData) {
            form.append([key], formData[key]);
        }
        axios.post(`/api/admin/v1/add-edp-sub-sub-category`, form, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
            },
        }).then((res) => {
            toast.success(res.data?.message);
            navigate("/edp-sub-sub-category");
        }).catch((err) => {
            toast.error(err.response.data);
            setErrors(err.response.data.errors);
        });
    };

    useEffect(() => {
        dispatch(getEdpCourseCatgeory(auth.token));
        dispatch(getEdpCourseSubCatgeory(auth.token));
    }, [auth]);

    useEffect(() => {
        if (formData.category_id) {
            axios(
                `/api/admin/v1/get-edp-sub-category-list/${formData.category_id}`
            ).then(({ data }) => {
                setEdpCourseSubCategory(data?.data);
            }).catch((err) => console.error(err));
        } else {
            setEdpCourseSubCategory([]);
        }
    }, [formData.category_id]);

    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <h5 className="text-white">Add EDP Sub Sub Category</h5>
                </div>
            </div>
            <div className="bg-white px-3 py-4 rounded-2  my-4">
                <div className="row">
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Edp Category <span className="text-danger fs-4">*</span></Label>
                            <Input className="text-black" value={formData?.category_id} type="select" name="category_id" onChange={inputHandler}>
                                <option value="">Select</option>
                                {edpCourseCategories?.map?.((item) => {
                                    return (<option value={item.id} key={item.id}>{item?.name}</option>)
                                })}
                            </Input>
                            <span className="d-block text-danger">{" "}{errors?.category_id}{" "}</span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>EDP Sub Category <span className="text-danger fs-4">*</span></Label>
                            <Input className="text-black" value={formData?.sub_category_id} type="select" name="sub_category_id" onChange={inputHandler}>
                                <option value="">Select</option>
                                {EdpCourseSubCategory?.map?.((item) => {
                                    return (
                                        <option value={item.id} key={item.id}>
                                            {item?.name}
                                        </option>
                                    );
                                })}
                            </Input>
                            <span className="d-block text-danger">{" "}{errors?.sub_category_id}{" "}</span>
                        </FormGroup>
                    </div>

                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>
                                Title English <span className="text-danger fs-4">*</span>
                            </Label>
                            <Input
                                value={formData?.title}
                                name="title"
                                onChange={inputHandler}
                            />
                            <span className="d-block text-danger"> {errors?.title} </span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Title Hindi</Label>
                            <Input
                                value={formData?.hindi_title}
                                name="hindi_title"
                                onChange={inputHandler}
                            />
                        </FormGroup>
                    </div>

                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>English Name <span className="text-danger fs-4">*</span></Label>
                            <Input value={formData?.name} name="name" onChange={inputHandler} invalid={errors?.name} />
                            <span className="d-block text-danger"> {errors?.name} </span>
                        </FormGroup>
                    </div>

                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Hindi Name</Label>
                            <Input
                                value={formData?.hindi_name}
                                name="hindi_name"
                                onChange={inputHandler}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Short Description </Label>
                            <Input
                                name="short_description"
                                value={formData?.short_description}
                                type="textarea"
                                onChange={inputHandler}
                            />
                            <span className="d-block text-danger">
                                {errors?.short_description}
                            </span>
                        </FormGroup>
                    </div>

                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Description</Label>
                            <Input
                                name="description"
                                value={formData?.description}
                                type="textarea"
                                onChange={inputHandler}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Hindi Full Description</Label>
                            <Input
                                value={formData?.hindi_description}
                                name="hindi_description"
                                type="textarea"
                                onChange={inputHandler}
                            />
                        </FormGroup>
                    </div>

                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Image </Label>
                            <Input
                                type="file"
                                name="image"
                                onChange={(e) =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        image: e.target.files[0],
                                    }))
                                }
                            />
                            <span className="d-block text-danger">{errors?.image}</span>
                        </FormGroup>
                    </div>

                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Url</Label>
                            <Input value={formData?.url} name="url" onChange={inputHandler} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label className="text-black">Select Type</Label>
                            <Input
                                value={formData?.type}
                                type="select"
                                name="type"
                                onChange={inputHandler}
                            >
                                <option value="">Select</option>
                                <option value="Free">Free</option>
                                <option value="Paid">Paid</option>
                            </Input>
                        </FormGroup>
                    </div>

                    {formData.type === "Paid" ? (
                        <div className="col-md-4 offset-md-1">
                            <FormGroup>
                                <Label className="text-black">Amount</Label>
                                <Input
                                    value={formData?.price}
                                    name="price"
                                    onChange={inputHandler}
                                />
                            </FormGroup>
                        </div>
                    ) : null}
                    <div className="col-md-4 offset-md-1">
                        <FormGroup switch>
                            <Input
                                type="switch"
                                checked={formData?.status}
                                onChange={inputHandler}
                                name="status"
                            />
                            <Label check>Status</Label>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-4">
                        <Button color="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                        <Button className="ms-3" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEdpSubSubCategory;
