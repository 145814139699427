import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, FormGroup, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { getEdpCourseTypes } from "../../redux/actions/EdpCourseActions";
const EditEdpCategory = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        name: "",
        hindi_name: "",
        image: "",
        short_description: "",
        status: 1,
    });
    const navigate = useNavigate();
    const {
        AuthReducer: { auth },
    } = useSelector((state) => state);

    const [errors, SetErrors] = useState({});
    useEffect(() => {
        dispatch(getEdpCourseTypes(auth.token));
    }, [auth.token]);

    const inputHandler = (e) => {
        const { name, value } = e.target;
        if (name === "image") {
            setFormData({ ...formData, [name]: e.target.files[0] });
        }
        if (name === "status") {
            setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    useEffect(() => {
        if (id) {
            axios(`/api/admin/v1/edp-category-edit/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth?.token}`,
                },
            }).then((res) => {
                setFormData((prev) => {
                    return {
                        ...prev,
                        name: res.data?.edpCourseCategories?.name,
                        hindi_name: res.data?.edpCourseCategories?.hindi_name,
                        short_description: res.data?.edpCourseCategories?.short_description,
                        status: res.data?.edpCourseCategories?.status,
                    };
                });
            }).catch((err) => {
                toast.error(err.response.data);
                SetErrors(err.response.data);
            });
        }
    }, [id, auth]);

    const handleSubmit = () => {
        const form = new FormData()
        for (const key in formData) {
            form.append([key], formData[key])
        }
        axios.put(`/api/admin/v1/edp-category-update/${id}`, form, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth?.token}`
            }
        }).then((res) => {
            toast.success(res.data?.message)
            navigate(-1)
        }
        ).catch((err) => toast.error(err.response.data))
    }

    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <h4 className="text-white">Edit Edp Category</h4>
                </div>
            </div>
            <div className='bg-white px-3 py-4 rounded-2  my-4' >
                <div className="row">
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>English Name <span className="text-danger fs-4">*</span></Label>
                            <Input name='name' invalid={errors?.name} value={formData?.name} onChange={inputHandler} />
                            <span className="d-block text-danger">
                                {" "}
                                {errors?.name}{" "}
                            </span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Hindi Name</Label>
                            <Input name='hindi_name' value={formData?.hindi_name} onChange={inputHandler} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Short Description</Label>
                            <Input name='short_description' value={formData?.short_description} type="textarea" onChange={inputHandler} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Upload Picture</Label>
                            <Input type='file' name="image" onChange={(e) => setFormData(prev => ({ ...prev, image: e.target.files[0] }))} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup switch>
                            <Input
                                type="switch"
                                checked={formData?.status}
                                onChange={inputHandler}
                                name="status"
                            />
                            <Label check>Status</Label>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-4">
                        <Button color='primary' onClick={handleSubmit}>
                            Submit
                        </Button>
                        <Button className='ms-3' onClick={() => navigate(-1)}  >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditEdpCategory;
