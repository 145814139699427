import axios from "axios";

export const getUser =
  (token, limit, page, search = "", sort = "dsc", startDate, endDate) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_USER_PENDING" });
        const response = await axios(
          `/api/admin/v1/get-user?limit=${limit || 10}&page=${page || 0
          }&search=${search}&sort=${sort}&startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_USER_FULFILLED",
          payload: response.data,
        });
      } catch (error) {
        dispatch({ type: "GET_USER_FAILED", payload: error.response.data });
      }
    };

export const getServiceUser =
  (token, limit, page, search = "", sort = "dsc", startDate, endDate) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_SERVICE_USER_PENDING" });
        const response = await axios(
          `/api/admin/v1/get-service-user?limit=${limit || 10}&page=${page || 0
          }&search=${search}&sort=${sort}&startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_SERVICE_USER_FULFILLED",
          payload: response.data,
        });
      } catch (error) {
        dispatch({ type: "GET_SERVICE_USER_FAILED", payload: error.response.data });
      }
    };
export const getEdpUser =
  (token, limit, page, search = "", sort = "dsc", startDate, endDate) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_EDP_USER_PENDING" });
        const response = await axios(
          `/api/admin/v1/get-edp-user?limit=${limit || 10}&page=${page || 0
          }&search=${search}&sort=${sort}&startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_EDP_USER_FULFILLED",
          payload: response.data,
        });
      } catch (error) {
        dispatch({ type: "GET_EDP_USER_FAILED", payload: error.response.data });
      }
    };